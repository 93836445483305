<template>
  <div class="article">
    <div v-if="flag" class="content" v-html="content"></div>
    <div v-else>
      <img class="kong" src="../assets/imgs/kong.png" alt="" />
      <div class="title">暂无内容</div>
    </div>
  </div>
</template>
<script>
import { goodsContent } from "@/api/test/index";
export default {
  name: "productDetails",
  props: {},
  data() {
    return {
      content: undefined,
      flag: true,
    };
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      const url = window.location.href; //获取当前url
      const dataArr = url.split("?")[1];
      let goodsId = dataArr.split("&")[0].split("=")[1];
      let goodsType = dataArr.split("&")[1].split("=")[1];
      goodsContent({ goodsId, goodsType })
        .then((res) => {
          this.content = res.data.content;
        })
        .catch(() => {
          this.flag = false;
        });
    },
  },
};
</script>
<style scoped>
::v-deep .content img {
  width: 100%;
}
.kong {
  display: block;
  margin: 300px auto 0;
}
.title {
  margin-top: 50px;
  text-align: center;
  color: #999999;
}
</style>
